import CocaCola from '../assets/images/drinks-images/coca-cola-classic.jpeg'
// import MountainDew from '../assets/images/drinks-images/coca-cola-classic.jpeg'
// import PepsiCola from '../assets/images/drinks-images/coca-cola-classic.jpeg'
// import Milo from '../assets/images/drinks-images/coca-cola-classic.jpeg'
// import DrPepper from '../assets/images/drinks-images/coca-cola-classic.jpg'
// import SpriteCaffeine from '../assets/images/drinks-images/coca-cola-classic.jpeg'
// import GreatValueSweet from '../assets/images/drinks-images/Great Value.jpeg'
// import SToK from '../assets/images/drinks-images/SToK.webp'
// import Lipton from '../assets/images/drinks-images/Lipton Green Tea.jpeg'
// import CanadaDry from '../assets/images/drinks-images/Canada Dry Ginger.jpg'
// import DrPepperZero from '../assets/images/drinks-images/Dr Pepper Zero Sugar.jpeg'
// import DietDrPepper from '../assets/images/drinks-images/Diet Dr Pepper Soda.jpg'
// import AquafinaPurified from '../assets/images/drinks-images/Aquafina Purified.jpeg'
// import PureLife from '../assets/images/drinks-images/Pure Life Purified.jpg'
// import SamsCola from '../assets/images/drinks-images/Sams Cola Soda.jpeg'
// import RedBull from '../assets/images/drinks-images/Red Bull.jpg'
// import Anw from '../assets/images/drinks-images/A&W Caffeine-Free.png'
// import PepsiSoda from '../assets/images/drinks-images/pepsi.jpeg'
// import GreatValue from '../assets/images/drinks-images/Great Value.jpeg'
// import Sunkist from '../assets/images/drinks-images/Sunkist Orange.jpeg'
// import GreatValueTwist from '../assets/images/drinks-images/Great Value Twist Up.jpeg'
// import FantaOrange from '../assets/images/drinks-images/Fanta Orange Soda.jpeg'
//Pasta
import ChickenAlfredo from '../assets/images/drinks-images/coca-cola-classic.jpeg'
// import AllaGricia from '../assets/images/drinks-images/coca-cola-classic.jpeg'
// import SheetPan from '../assets/images/drinks-images/coca-cola-classic.jpeg'
// import AllaVodka from '../assets/images/pasta-images/Penne Alla Vodka.jpeg'
// import BakedSpaghetti from '../assets/images/pasta-images/Baked Spaghetti.jpeg'
// import PotatoGnocchi from '../assets/images/pasta-images/pasta-6.jpeg'
// import BakedZiti from '../assets/images/pasta-images/Ultimate Baked Ziti.jpeg'
// import CacioPepe from '../assets/images/pasta-images/Cacio e Pepe.jpeg'
// import PastaPuttanesca from '../assets/images/pasta-images/Pasta Puttanesca.jpeg'
// import ItalianPastaSalad from '../assets/images/pasta-images/Italian Pasta Salad.jpeg'
// import Lasagna from '../assets/images/pasta-images/Lasagna Roll Ups.jpeg'
// import ToastedRavioli from '../assets/images/pasta-images/Toasted Ravioli.jpeg'
// import OrecchietteBroccoli from '../assets/images/pasta-images/Orecchiette with Broccoli Rabe.jpeg'
// import LambRagu from '../assets/images/pasta-images/Lamb Ragù.jpeg'
// import LasagnaBolognese from '../assets/images/pasta-images/Lasagna Bolognese.jpeg'
// import LemonRicotta from '../assets/images/pasta-images/Lemon Ricotta Pasta.jpeg'
// import StuffedRigatoni from '../assets/images/pasta-images/Stuffed Rigatoni.jpeg'
// import FiveCheese from '../assets/images/pasta-images/Five-Cheese Ziti Al Forno.jpeg'
// import ChickenFlorentine from '../assets/images/pasta-images/Chicken Florentine Pasta.jpeg'
// import MacCheese from '../assets/images/pasta-images/Italian Mac and Cheese.jpeg'
//Pizza
// import CheesePizza from '../assets/images/drinks-images/coca-cola-classic.jpeg'
// import ExtraCheesePizza from '../assets/images/drinks-images/coca-cola-classic.jpeg'
// import VeggiePizza from '../assets/images/drinks-images/coca-cola-classic.jpeg'
// import PepperoniPizza from '../assets/images/pepperoni-pizza.jpg'
// import MeatPizza from '../assets/images/meat-pizza.jpg'
// import MargheritaPizza from '../assets/images/margherita-pizza.jpg'
// import BBQPizza from '../assets/images/BBQ-chicken-pizza.jpg'
// import AllaPalla from '../assets/images/pizza-alla-pala.jpg'
// import Prosciutto from '../assets/images/Prosciutto-e-Funghi-Pizza.jpeg'
// import DetroitStyle from '../assets/images/Detroit-Style-pizza.jpeg'
// import GarlicFingers from '../assets/images/Garlic-Fingers-pizza.jpeg'
// import StLous from '../assets/images/St-Louis-Style-Pizza.jpg'
// import Rustica from '../assets/images/Pizza-Rustica.jpg'
// import Genovese from '../assets/images/Genovese.jpeg'
// import Efichi from '../assets/images/Pizza-e-fichi.jpeg'
// import Apizza from '../assets/images/Apizza.jpg'
// import StuffedPizza from '../assets/images/Stuffed-Pizza.jpg'
// import Pugliese from '../assets/images/Pugliese.jpeg'
// import TomatoPie from '../assets/images/Tomato-Pie.jpeg'
// import Fugaza from '../assets/images/fugazza.jpeg'
// import DiZucca from '../assets/images/di-zucca.jpeg'
// import Fugazeta from '../assets/images/Fugazzeta.jpeg'
// import Carbonara from '../assets/images/carbonara.jpeg'
// import GranmaPie from '../assets/images/Grandma-Pie.jpeg'
// import Padellino from '../assets/images/padellino.jpeg'
// import CaliforniaStyle from '../assets/images/California-Style.jpeg'
// import Fritta from '../assets/images/fritta.jpeg'
// import Romana from '../assets/images/romana.jpeg'
// import ThinCrust from '../assets/images/Thin-Crust.jpeg'
// import GreekStyle from '../assets/images/Greek-Style.jpeg'
// import PizzaRomana from '../assets/images/PizzaRomana.jpeg'
// //Sushi
// import SalmonPoke from '../assets/images/sushi-images/sushi-19.jpeg'
// import VeggiePoke from '../assets/images/sushi-images/sushi-26.jpeg'
// import MiniSalmon from '../assets/images/sushi-images/sushi-12.jpeg'
// import OmbosSeth from '../assets/images/sushi-images/sushi-23.jpeg'
// import VeggieSet from '../assets/images/sushi-images/sushi-27.jpeg'
// import FriendlySet from '../assets/images/sushi-images/sushi-8.jpeg'
// import SunnyPhiladelphia from '../assets/images/sushi-images/sushi-22.jpeg'
// import HiddenDragon from '../assets/images/sushi-images/sushi-9.jpeg'
// import HottestTaisho from '../assets/images/sushi-images/sushi-24.jpeg'
// import BurningTaisho from '../assets/images/sushi-images/sushi-3.jpeg'
// import BlissfulEel from '../assets/images/sushi-images/sushi-2.jpeg'
// import Sebastian from '../assets/images/sushi-images/sushi-20.jpeg'
// import DoubleSalmon from '../assets/images/sushi-images/sushi-6.jpeg'
// import ClockworkOrange from '../assets/images/sushi-images/sushi-4.jpeg'
// import KiwiRoll from '../assets/images/sushi-images/sushi-11.jpeg'
// import Siamese from '../assets/images/sushi-images/sushi-25.jpeg'
// import SalmonMaki from '../assets/images/sushi-images/sushi-18.jpeg'
// import NigiriWithRoasted from '../assets/images/sushi-images/sushi-15.jpeg'
// import NigiriWithSalmon from '../assets/images/sushi-images/sushi-16.jpeg'
// import NigiriWithEel from '../assets/images/sushi-images/sushi-14.jpeg'
// import VulcanTobiko from '../assets/images/sushi-images/sushi-29.jpeg'
// import SalmonGunkan from '../assets/images/sushi-images/sushi-17.jpeg'
// import VeryVegetarian from '../assets/images/sushi-images/sushi-28.jpeg'
// import SushiOne from '../assets/images/sushi-images/sushi-1.jpeg'
// import CucumberMaki from '../assets/images/sushi-images/sushi-5.jpeg'
// import HungrySet from '../assets/images/sushi-images/sushi-10.jpeg'
// import NigiriOcean from '../assets/images/sushi-images/sushi-13.jpeg'
// import FoggyAlbion from '../assets/images/sushi-images/sushi-7.jpeg'
// import StanleyKubrick from '../assets/images/sushi-images/sushi-21.jpeg'





// axios.get('http://89.104.69.113:8000/api/backend/dish/?format=json')
//   .then(function (response) {
//     // обработка успешного запроса
//     console.log(response);
//     const  allProductsData1=response.data;
//   })
//   .catch(function (error) {
//     // обработка ошибки
//     console.log(error);
    







//   })

//   .finally(function () {
//     // выполняется всегда
//   });





export const  allProductsData1 = [
  // {
  //   id: 'cheese-pizza',
  //   image: CheesePizza,
  //   name: 'Сырная пицца',
    
  //   status: 'available',
  //   description: 'Тесто для пиццы, пицца-соус, сыр проволоне, сыр моцарелла.',
    
  //   price: (200).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   id: 'veggie-pizza',
  //   image: VeggiePizza,
  //   name: 'Вегетарианская пицца',
  //   status: 'available',
  //   description:
  //     'Соус для пиццы, лук, стручковый перец, ананас, имбирь, соус хотшот, кориандр, чесночный соус, масляный соус, сыр.',
  //   price: (900).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'pepperoni-pizza',
  //   image: PepperoniPizza,
  //   name: 'Пицца Пепперони',
  //   status: 'available',
  //   description:
  //     'Дрожжи для пиццы, пепперони, томатная паста, сыр моцарелла, сахар.',
  //   price: (500).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'meat-pizza',
  //   image: MeatPizza,
  //   name: 'Мясная пицца',
  //   status: 'available',
  //   description:
  //     'Колбаса, тесто для пиццы, бекон, соус для пиццы, хлопья красного перца.',
  //   price: (1000).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'margherita-pizza',
  //   image: MargheritaPizza,
  //   name: 'Пицца Маргерита',
  //   status: 'available',
  //   description:
  //   'Тесто для пиццы, томатный соус, свежая моцарелла, оливковое масло, листья базилика.',
  //   price: (800).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'bbq-chicken-pizza',
  //   image: BBQPizza,
  //   name: 'Пицца с курицей барбекю',
  //   status: 'available',
  //   description:
  //     'Жареная курица, тесто для пиццы, соус ббк, копченый сыр чеддер, сыр моцарелла.',
  //   price: (1000).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'alla-pala-pizza',
  //   image: AllaPalla,
  //   name: 'Пицца алла пала',
  //   status: 'available',
  //   description: 'Сыр, помидоры черри, руккола, оливковое масло, дрожжи.',
  //   price: (1200).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'prosciutto-e-funghi-pizza',
  //   image: Prosciutto,
  //   name: 'Пицца с ветчиной и грибами',
  //   status: 'available',
  //   description:
  //     'Итальянская колбаса, трюфельное масло, тесто для пиццы, помидоры Сан Марцано, сыр Боккончини.',
  //   price: (800).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'detroit-style-pizza',
  //   image: DetroitStyle,
  //   name: 'Пицца по-детройтски',
  //   status: 'available',
  //   description:
  //     'Тесто для пиццы, соус для пиццы, сыр, быстрорастворимые дрожжи, оливковое масло.',
  //   price: (1100).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'garlic-fingers',
  //   image: GarlicFingers,
  //   name: 'Чесночные пальчики',
  //   status: 'available',
  //   description:
  //     'Хлебная мука, сыр моцарелла, сливочное масло, оливковое масло, быстродействующие дрожжи.',
  //   price: (700).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'st-ouis-style-pizza',
  //   image: StLous,
  //   name: 'Пицца в стиле Сент-Луиса',
  //   status: 'available',
  //   description:
  //     'Жидкий дым, швейцарский сыр, белый сыр чеддер, светлый кукурузный сироп, соус для пиццы.',
  //   price: (1300).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'rustica-pizza',
  //   image: Rustica,
  //   name: 'Пицца Рустика',
  //   status: 'available',
  //   description: 'Прошутто, пепперони, моцарелла, корочка, яйца.',
  //   price: (1300).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'pesto-genovese-pizza',
  //   image: Genovese,
  //   name: 'Пицца песто Дженовезе',
  //   status: 'available',
  //   description:
  //     'Кедровые орехи, картофель, побеги снежного горошка, лимон, моцарелла "Буйвол.',
  //   price: (1300).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'e-fichi-pizza',
  //   image: Efichi,
  //   name: 'Пицца с инжиром',
  //   status: 'available',
  //   description: 'Кокосовая мука, трюфельное масло, инжирный джем, сыр, прошутто.',
  //   price: (1200).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'apizza-pizza',
  //   image: Apizza,
  //   name: 'Apizza',
  //   status: 'available',
  //   description:
  //     'Сыр рикотта, томатный соус, свежий базилик песто, свежий сыр моцарелла, манная мука.',
  //   price: (1100).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'stuffed-pizza',
  //   image: StuffedPizza,
  //   name: 'Пицца с начинкой',
  //   status: 'available',
  //   description:
  //     'Острая итальянская колбаса, сладкая итальянская колбаса, тесто для пиццы, хлопья красного перца, оливковое масло.',
  //   price: (1300).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'pugliese-pizza',
  //   image: Pugliese,
  //   name: 'Пицца Pugliese',
  //   status: 'available',
  //   description:
  //     'Слоппи-джо, банановый перец, говяжий фарш, тесто для пиццы, сыр рикотта.',
  //   price: (900).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'tomato-pie',
  //   image: TomatoPie,
  //   name: 'Томатный пирог',
  //   status: 'available',
  //   description:
  //     'Тесто для пиццы, Сан-Марцано, хлопья красного перца, камень для пиццы, оливковое масло.',
  //   price: (1500).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'fugazza',
  //   image: Fugaza,
  //   name: 'Побег',
  //   status: 'available',
  //   description: 'Сыр моцарелла, сахар, сухие дрожжи, мука высшего сорта.',
  //   price: (1000).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'fiori-di-zucca',
  //   image: DiZucca,
  //   name: 'Пицца с тыквенными цветами',
  //   status: 'available',
  //   description:
  //     'Цветы кабачка, тесто для пиццы, моцарелла "Буйвол", оливковое масло.',
  //   price: (800).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'fugazzeta',
  //   image: Fugazeta,
  //   name: 'Fugazzeta',
  //   status: 'available',
  //   description:
  //     'Сыр фета, сыр моцарелла, сыр пармезан, оливковое масло, дрожжи.',
  //   price: (900).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'carbonara-pizza',
  //   image: Carbonara,
  //   name: 'Пицца Карбонара',
  //   status: 'available',
  //   description:
  //     'Охлажденный корж для пиццы, сыр Монтерей Джек, сыр Пармезан, сливочное масло, куриный бульон.',
  //   price: (800).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'grandma-pie',
  //   image: GranmaPie,
  //   name: 'Бабушкин пирог',
  //   status: 'available',
  //   description:
  //     'Темно-коричневый сахар, помидоры Сан Марцано, томатная паста, хлебная мука, хлопья красного перца.',
  //   price: (1100).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'pizza-al-padellino',
  //   image: Padellino,
  //   name: 'Пицца на сковороде',
  //   status: 'available',
  //   description:
  //     'Сыр моцарелла, прошутто ди парма, тесто, руккола, черный перец',
  //   price: (1000).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'california-style-pizza',
  //   image: CaliforniaStyle,
  //   name: 'Пицца по-калифорнийски',
  //   status: 'available',
  //   description:
  //     'Сыр Фета, маринованные сердца артишоков, корка для пиццы, красный болгарский перец, красный лук.',
  //   price: (1500).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'pizza-fritta',
  //   image: Fritta,
  //   name: 'Жареная пицца',
  //   status: 'available',
  //   description:
  //     'Сыр рикотта, томатный соус, тип 00, основа для неаполитанской пиццы, сыр моцарелла.',
  //   price: (800).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'pinsa-romana',
  //   image: Romana,
  //   name: 'Pinsa romana',
  //   status: 'available',
  //   description:
  //     'Рисовая мука, оливковое масло, обычная мука, морская соль, сухие дрожжи.',
  //   price: (500).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'chicago-thin-crust-pizza',
  //   image: ThinCrust,
  //   name: 'Пицца с тонкой корочкой "Чикаго”',
  //   status: 'available',
  //   description:
  //     'Сладкая итальянская колбаса, манная мука, пицца-соус, джардинира, сахар.',
  //   price: (1300).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'greek-style-pizza',
  //   image: GreekStyle,
  //   name: 'Пицца в греческом стиле',
  //   status: 'available',
  //   description:
  //     'Белый чеддер, соус, томатная паста, хлебная мука, хлопья красного перца.',
  //   price: (800).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'pizza-romana',
  //   image: PizzaRomana,
  //   name: 'Пицца Романа',
  //   status: 'available',
  //   description: 'Свежая моцарелла, хлебная мука, помидоры, оливковое масло, дрожжи',
  //   price: (1100).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'extra-cheese-pizza',
  //   image: ExtraCheesePizza,
  //   name: 'Пицца с сыром',
  //   status: 'available',
  //   description: 'Домашний соус для пиццы, свежий сыр моцарелла, сыр пармезан, оливковое масло',
  //   price: (500).toFixed(2),
  //   category_name: 'Pizza',
  //   attributes: [
  //     {
  //       id: 'size',
  //       name: 'size',
  //       attributeOptions: [
  //         { id: 'small', value: 'small', slices: '6' },
  //         { id: 'medium', value: 'medium', slices: '8' },
  //         { id: 'large', value: 'large', slices: '10' },
  //         { id: 'x-large', value: 'x-large', slices: '12' },
  //       ],
  //     },
  //   ],
  // },

  {
    id: 'chicken-alfredo',
    image: CocaCola,
    image_S: CocaCola,
    name: 'Курица Альфредо',
    // status: 'available',
    description:
      'Куриная грудка без кожи, сливки, оливковое масло, пармиджано реджано, черный перец.',
    price: (900).toFixed(2),
    category_name: 'Паста',
    attributes: [],
  },
  {
    id: 'pasta-alla-gricia',
    image: CocaCola,
    image_S: CocaCola,
    name: 'Паста Алла Гриция',
    // status: 'available',
    description:
      'Паста ригатони, панчетта, оливковое масло, пекорино романо, черный перец.',
    price: (900).toFixed(2),
    category_name: 'Паста',
    attributes: [],
  },
  {
    id: 'sheet-pan-burrata-caprese-gnocchi',
    image: CocaCola,
    image_S: CocaCola,
    name: 'Клецки Буррата Капрезе на сковороде',
    // status: 'available',
    description:
      'Паста пенне, сметана, жареная курица, помидоры черри, кудрявая капуста.',
    price: (900).toFixed(2),
    category_name: 'Паста',
    attributes: [],
  },
  //  {
  //   id: 'salmon-poke',
  //   image: SalmonPoke,
  //   name: 'Salmon Poke',
  //   status: 'available',
  //   description:
  //     'Лосось, авокадо, рис, капуста, морковь, зеленый лук, кунжут.',
  //   price: (1000).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },

  // {
  //   id: 'veggie-poke',
  //   image: VeggiePoke,
  //   name: 'Veggie Poke',
  //   status: 'available',
  //   description:
  //     'Рис, авокадо, капуста, морковь, имбирь, зеленый лук, кунжут.',
  //   price: (800).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'mini-salmon-set-22-pcs',
  //   image: MiniSalmon,
  //   name: 'Mini Salmon Set - 22Pcs',
  //   status: 'available',
  //   description:
  //     'Двойной ролл с лососем, Калифорния, нигири из лосося - 2 шт, нигири из угря - 2 шт, Вулкан Гункан - 2 шт.',
  //   price: (3500).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'the-bull-of-ombos-set-32-pcs',
  //   image: OmbosSeth,
  //   name: 'Набор "Бык из Омбоса" - 32 шт.',
  //   status: 'available',
  //   description:
  //     'Двойной ролл с лососем, Солнечная Филадельфия, Лосось маки раки, Огурец Maki Raki.',
  //   price: (3000).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'veggie-set-32-pcs',
  //   image: VeggieSet,
  //   name: 'Вегетарианский  Сэт 32Pcs',
  //   status: 'available',
  //   description:
  //     'Очень вегетарианский ролл, Очень вегетарианский Maki, Огурец Maki Raki, Авокадо Maki Raki.',
  //   price: (1600).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'friendly-set-40-pcs',
  //   image: FriendlySet,
  //   name: 'Дружеский набор 40 шт.',
  //   status: 'available',
  //   description:
  //     'Калифорния, острый ролл с лососем, блаженный ролл с угрем, крабовый ролл "Себастьян", огурец Maki Raki.',
  //   price: (3000).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'sunny-philadelphia-roll-8-pcs',
  //   image: SunnyPhiladelphia,
  //   name: 'Солнечный ролл Филадельфия 8 шт.',
  //   status: 'available',
  //   description: 'Лосось, сливочный сыр, авокадо, рис, кунжут, нори.',
  //   price: (1200).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'hidden-dragon-roll-8-pcs',
  //   image: HiddenDragon,
  //   name: 'Ролл "Скрытый дракон” 8 шт.',
  //   status: 'available',
  //   description:
  //     'Лосось, сливочный сыр, авокадо, тобико, рис, кунжут, огурец, нори.',
  //   price: (1300).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'double-salmon-roll-8-pcs',
  //   image: DoubleSalmon,
  //   name: 'Двойной ролл с лососем 8 шт.',
  //   status: 'available',
  //   description:
  //     'Лосось, сливочный сыр, авокадо, кунжут, дополнительный ломтик лосося, огурец, рис, нори.',
  //   price: (1400).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'the-hottest-taisho',
  //   image: HottestTaisho,
  //   name: 'Самый горячий Тайсё',
  //   status: 'available',
  //   description:
  //     'Лосось, сливочный сыр, авокадо, огурец, рис - все подается с выбранным вами соусом.',
  //   price: (1200).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'burning-taisho',
  //   image: BurningTaisho,
  //   name: 'Горящий Тайшо',
  //   status: 'available',
  //   description: 'Лосось, авокадо, рис, огурец, сливочный сыр.',
  //   price: (1000).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'blissful-eel-roll-8-pcs',
  //   image: BlissfulEel,
  //   name: 'Блаженный ролл с угрем 8 шт.',
  //   status: 'available',
  //   description:
  //     'Угорь, сливочный сыр, авокадо, тобико, кунжут, рис, нори.',
  //   price: (1400).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'sebastian-the-crab-roll-8-pcs',
  //   image: Sebastian,
  //   name: 'Крабовый ролл "Себастьян" 8 шт.',
  //   status: 'available',
  //   description:
  //     'Крабовый с уникальным соусом, сливочный сыр, авокадо, хлопья сушеного тунца, кунжут, рис с огурцом, нори.',
  //   price: (1300).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'double-salmon-roll-8-pcs-3',
  //   image: DoubleSalmon,
  //   name: 'Двойной ролл с лососем 8 шт.',
  //   status: 'available',
  //   description:
  //     'Лосось, сливочный сыр, авокадо, кунжут, дополнительный ломтик лосося, огурец, рис, нори.',
  //   price: (1000).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'clockwork-orange-roll-8-pcs',
  //   image: ClockworkOrange,
  //   name: 'Заводной апельсин ролл 8 шт.',
  //   status: 'available',
  //   description: 'Лосось, апельсин, сливочный сыр, дополнительный ломтик лосося, нори.',
  //   price: (1100).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'kiwi-roll-8-pcs',
  //   image: KiwiRoll,
  //   name: 'Киви рулет 8 шт.',
  //   status: 'available',
  //   description:
  //     'Лосось, киви, морковь, сливочный сыр, тобико, огурец, рис, нори.',
  //   price: (1300).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'the-roll-of-siamese',
  //   image: Siamese,
  //   name: 'Ролл сиамов',
  //   status: 'available',
  //   description: 'Лосось, огурец, морковь, икра, рис.',
  //   price: (1500).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'salmon-maki-raki-8-pcs',
  //   image: SalmonMaki,
  //   name: 'Лосось Маки Раки 8 шт.',
  //   status: 'available',
  //   description: 'Лосось, сливочный сыр, рис, нори.',
  //   price: (900).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'nigiri-with-roasted-salmon-1-pc',
  //   image: NigiriWithRoasted,
  //   name: 'Нигири с жареным лососем 1 шт.',
  //   status: 'available',
  //   description: 'Жареный лосось, рис.',
  //   price: (500).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'nigiri-with-salmon-and-orange-1-pc',
  //   image: NigiriWithSalmon,
  //   name: 'Нигири с лососем и апельсином 1 шт.',
  //   status: 'available',
  //   description: 'Лосось, апельсин, рис.',
  //   price: (400).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'nigiri-with-eel-1-pc',
  //   image: NigiriWithEel,
  //   name: 'Нигири с угрем 1 шт.',
  //   status: 'available',
  //   description: 'Угорь, рис.',
  //   price: (600).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'vulcan-tobiko',
  //   image: VulcanTobiko,
  //   name: 'Vulcan Tobiko',
  //   status: 'available',
  //   description: 'Вкуснейший гункан с тобико и рисом.',
  //   price: (400).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'salmon-gunkan-with-cream-cheese',
  //   image: SalmonGunkan,
  //   name: 'Гункан из лосося со сливочным сыром',
  //   status: 'available',
  //   description: 'Идеальный рис для суши, креветки нигири, сливочный сыр, васаби.',
  //   price: (1100).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'very-vegetarian-maki-8-pcs',
  //   image: VeryVegetarian,
  //   name: 'Очень вегетарианские маки 8 шт.',
  //   status: 'available',
  //   description: 'Авокадо, сливочный сыр, огурец, рис, нори.',
  //   price: (500).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'avocado-maki-raki-8-pcs',
  //   image: SushiOne,
  //   name: 'Маки из авокадоRaki 8Pcs',
  //   status: 'available',
  //   description: 'Авокадо, сливочный сыр, рис, нори.',
  //   price: (500).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'cucumber-maki-raki-8-pcs',
  //   image: CucumberMaki,
  //   name: 'Огурец Маки Раки 8 шт.',
  //   status: 'available',
  //   description: 'Огурец, кунжут, рис, нори.',
  //   price: (400).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'hungry-set-64-pcs',
  //   image: HungrySet,
  //   name: 'Голодный набор 64 шт.',
  //   status: 'available',
  //   description:
  //     'Калифорникейшн, Солнечный ролл Филадельфия, Пряный ролл с лососем, Блаженный ролл с угрем, Крабовый ролл Себастьян, Очень вегетарианский маки-раки, Огурец Maki Raki,  Авокадо Maki Raki.',
  //   price: (5000).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'nigiri-ocean-14-pcs',
  //   image: NigiriOcean,
  //   name: 'Океанские нигири - 14 шт.',
  //   status: 'available',
  //   description:
  //     '14 различных нигири: нигири из лосося - 2 шт, нигири из лосося с апельсином - 2 шт, нигири из угря - 2 шт, нигири из тунца - 2 шт, нигири из жареного тунца - 2 шт, нигири из жареного лосося - 2 шт, нигири из киви - 2 шт.',
  //   price: (4000).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'foggy-albion-26-pcs',
  //   image: FoggyAlbion,
  //   name: 'Туманный Альбион - 26 шт.',
  //   status: 'available',
  //   description:
  //     'Солнечная Филадельфия, краб Себастьян, огурец маки раки, гункан из лосося со сливочным сыром - 2 шт.',
  //   price: (3000).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  // {
  //   id: 'stanley-kubrick-24-pcs',
  //   image: StanleyKubrick,
  //   name: 'Стэнли Кубрик - 24 шт.',
  //   status: 'available',
  //   description:
  //     'Clockwork Orange Roll, Spicy Salmon Roll, Very Vegetarian Maki',
  //   price: (1100).toFixed(2),
  //   category_name: 'Sushi',
  //   attributes: [],
  // },
  {
    id: 'coca-cola-original-soda-pop',
    image: CocaCola,
    image_S: CocaCola,
    name: 'Coca-Cola Original Soda Pop',
    price: (900).toFixed(2),
    category_name: 'Паста',
    attributes: [],
  },

  {
    id: 'mountain-dew-citrus-soda-pop',
    image: CocaCola,
    image_S: CocaCola,
    name: 'Mountain Dew Citrus Soda Pop',
    price: (900).toFixed(2),
    category_name: 'Паста',
    attributes: [],
  },
  {
    id: 'pepsi-cola-soda-pop',
    image: CocaCola,
    image_S: CocaCola,
    name: 'Pepsi Cola Soda Pop',
    price: (900).toFixed(2),
    category_name: 'Паста',
    attributes: [],
  },
  // {
  //   id: 'nigiri-with-salmon-and-orange-1-pc-sale',
  //   image: NigiriWithSalmon,
  //   name: 'Нигири с лососем и апельсином 1 шт.',
  //   status: 'available',
  //   description: 'Лосось, апельсин, рис.',
  //   priceBefore: (400).toFixed(2),
  //   price: (300).toFixed(2),
  //   category_name: 'Sale',
  //   sale: true,
  //   attributes: [],
  // },
  // {
  //   id: 'pepperoni-pizza-sale',
  //   image: PepperoniPizza,
  //   name: 'Пицца Пепперони средняя',
  //   status: 'available',
  //   description:
  //     'Дрожжи для пиццы, пепперони, томатная паста, сыр моцарелла, сахар.',
  //   priceBefore: (500).toFixed(2),
  //   price: (300).toFixed(2),
  //   category_name: 'Sale',
  //   sale: true,
  //   attributes: [],
  // },
  // {
  //   id: 'meat-pizza-sale',
  //   image: MeatPizza,
  //   name: 'Мясная пицца средняя',
  //   status: 'available',
  //   description:
  //     'Колбаса, тесто для пиццы, бекон, соус для пиццы, хлопья красного перца.',
  //   priceBefore: (1000).toFixed(2),
  //   price: (800).toFixed(2),
  //   category_name: 'Sale',
  //   sale: true,
  //   attributes: [],
  // },
  // {
  //   id: 'margherita-pizza-sale',
  //   image: MargheritaPizza,
  //   name: 'Пицца "Маргерита" малая',
  //   status: 'available',
  //   description:
  //     'Тесто для пиццы, томатный соус, свежая моцарелла, оливковое масло, листья базилика.',
  //   priceBefore: (800).toFixed(2),
  //   price: (700).toFixed(2),
  //   category_name: 'Sale',
  //   sale: true,
  //   attributes: [],
  // },
  {
    id: 'veggie-pizza-sale',
    image: CocaCola,
    image_S: CocaCola,
    name: 'Большая вегетарианская пицца',
    // status: 'available',
    description:
      'Соус для пиццы, Лук, Капсикам, Ананас, Имбирь, Соус хотшот, Кориандр, Чесночный соус, Масляный соус, Сыр.',
    priceBefore: (900).toFixed(2),
    price: (700).toFixed(2),
    category_name: 'Горячие закуски',
    sale: true,
    attributes: [],
  },
  // {
  //   id: 'sebastian-the-crab-roll-8-pcs-sale',
  //   image: Sebastian,
  //   name: 'Крабовый ролл "Себастьян" 8 шт.',
  //   status: 'available',
  //   description:
  //     'Крабовый с уникальным соусом, сливочный сыр, авокадо, хлопья сушеного тунца, кунжут, рис с огурцом, нори.',
  //   priceBefore: (1300).toFixed(2),
  //   price: (1000).toFixed(2),
  //   category_name: 'Sale',
  //   sale: true,
  //   attributes: [],
  // },
 
  {
    id: 'cheese-pizza-sale',
    image: CocaCola,
    image_S: CocaCola,
    name: 'Большая сырная пицца',
    status: 'available',
    description: 'Тесто для пиццы, пицца-соус, сыр проволоне, моцарелла.',
    priceBefore: (900).toFixed(2),
    price: (900).toFixed(2),
    category_name: 'Горячие закуски',
    sale: true,
    attributes: [],
  },
 

]

// // Делаем запрос пользователя с данным ID
// axios.get('http://89.104.69.113:8000/api/backend/dish/?format=json')
//   .then(function (response) {
//     // обработка успешного запроса
//     console.log(response);
   
//   })
//   .catch(function (error) {
//     // обработка ошибки
//     console.log(error);
//   })
//   .finally(function () {
//     // выполняется всегда
//   });

