import imgOne375 from '../assets/images/pricings/img-one-375.jpg'
import imgTwo375 from '../assets/images/pricings/img-two-375.jpg'
import imgThree375 from '../assets/images/pricings/img-three-375.jpg'
import imgFour375 from '../assets/images/pricings/img-four-375.jpg'
import imgFive375 from '../assets/images/pricings/img-five-375.jpg'
import imgSix375 from '../assets/images/pricings/img-six-375.jpg'

const menuPricing = [
    {
        id: 1,
        img375: imgOne375,
        name: 'Блины с малиной и мороженым',
        price: 500.0,
        currency: '₽',
        description:
        'Восхитительный десерт, который сочетает в себе нежные тонкие блины, свежую малину и кремовое мороженое. Это идеальный способ побаловать себя в выходной день.',
    },
    {
        id: 2,
        img375: imgTwo375,
        name: 'Сэндвич с копчёным лососем',
        price: 900.0,
        currency: '₽',
        description:
        'Вкусное и питательное легко блюдо, которое подойдет как для завтрака, так и для перекуса.',
    },
    {
        id: 3,
        img375: imgThree375,
        name: 'Сэндвич с ветчиной и беконом',
        price: 500.0,
        currency: '₽',
        description:
        'Хрустящий хлеб, сочная ветчина и ароматный бекон делают этот сэндвич настоящим угощением.',
    },
    {
        id: 4,
        img375: imgFour375,
        name: 'Блины с яблоком и корицей',
        price: 1000.0,
        currency: '₽',
        description:
        'Сочетание нежных блинов с пряной начинкой из яблок и корицы подарит вам настоящее наслаждение.',
    },
    {
        id: 5,
        img375: imgFive375,
        name: 'Блины с ветчиной и сыром',
        price: 800.0,
        currency: '₽',
        description:
        'Сытное и вкусное легкое блюдо для завтрака, которое понравится всей семье.',
    },
    {
        id: 6,
        img375: imgSix375,
        name: 'Сэндвич с цыпленком и сыром',
        price: 1000.0,
        currency: '₽',
        description:
        'Сочетание нежного мяса цыплёнка и расплавленного сыра делает это блюдо особенно аппетитным.',
    },
]

export default menuPricing;