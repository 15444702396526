import React from "react";

const AddToCartButton = ({ singleProduct,
  selectedAttributes,
  handleAddProduct, targetAttribute, setTargetAttribute }) => {
  return (
    console.log(singleProduct.status),
    <button
      onClick={() => {
        handleAddProduct(singleProduct, selectedAttributes);
        setTargetAttribute(false);
      }}
      className={`passive-button-style ${singleProduct.status== "available"
        ? "active-add-to-cart"
        : "inactive-add-to-cart"
        }`}
        
         disabled={singleProduct.status!== "available"}
    >
      В заказ!
    </button>
  );
}
export default AddToCartButton;