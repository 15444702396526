import React from 'react'
//Images
import Visa from '../../assets/images/payment-methods/visa-icon.svg'
import MasterCard from '../../assets/images/payment-methods/mastercard-icon.svg'
import Mir from '../../assets/images/payment-methods/Mir.svg'
// import GooglePay from '../../assets/images/payment-methods/googlepay-icon.svg'

export default class FooterContact extends React.Component {
  render() {
    return (
      <section className="footer-contact  flex-container flex-column">
        <h3>Появились вопросы?</h3>
        <p>+7(999)-16-80-416</p>
        <p>+7(81834)5-20-62</p>
        <p>4-20hookah@mail.ru</p>
        <p>8:00am - 9:00pm</p>
        <section className="accepted-payments">
          <img width="50" height="50" src={Mir} alt="Mir pay" />
          <img width="50" height="50" src={Visa} alt="Visa card" />
          <img width="50" height="50" src={MasterCard} alt="Mastercard card" />
         
          {/* <img width="50" height="50" src={ApplePay} alt="Apple pay" /> */}
          {/* <img
            width="50"
            height="50"
            className="googlepay"
            src={GooglePay}
            alt="Google pay"
          /> */}
        </section>
      </section>
    )
  }
}
