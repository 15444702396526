

  export const AllCategories = [

    {
        name: "Меню",
        id: "0",
      },
    {
      name: "Салаты",
      id: "1",
    },
    {
      name: "Холодные закуски",
      id: "2",
    },
    {
      name: "Горячие закуски",
      id: "3",
    },
    {
      name: "Паста",
      id: "4",
    },
    {
      name: "Блюда из рыбы",
      id: "5",
    },
    {
      name: "Блины",
      id: "6",
    },
    {
      name: "Супы",
      id: "7",
    },
    {
      name: "Горячие блюда",
      id: "8",
    },
    {
      name: "Брускетты",
      id: "9",
    },
    {
      name: "Пицца",
      id: "10",
    },
    {
      name: "Завтраки",
      id: "11",
    },
    {
      name: "Сэндвичи",
      id: "12",
    },
    {
      name: "Соусы",
      id: "13",
    },
    {
      name: "Хлеб",
      id: "14",
    },
    {
      name: "Бургеры",
      id: "15",
    },
    {
      name: "Топпинги",
      id: "16",
    },
  ];
  
  // {
  //   name: "Меню",
  //   id: "Menu",
  // },
  // {
  //   name: "САЛАТЫ",
  //   id: "САЛАТЫ",
  // },
  // {
  //   name: "ГОРЯЧИЕ ЗАКУСКИ",
  //   id: "ГОРЯЧИЕ ЗАКУСКИ",
  // },
  // {
  //   name: "Паста",
  //   id: "Паста",
  // },
  // {
  //   name: "Напитки",
  //   id: "Drinks",
  // },
  // {
  //   name: "Скидки",
  //   id: "Sale",
  // },
  // ];
  
  
  
