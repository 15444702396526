//375px
import imgOne375 from '../assets/images/menu-preview/img-one-375.jpg'
import imgTwo375 from '../assets/images/menu-preview/img-two-375.jpg'
import imgThree375 from '../assets/images/menu-preview/img-three-375.jpg'
import imgFour375 from '../assets/images/menu-preview/img-four-375.jpg'

//700px
import imgOne700 from '../assets/images/menu-preview/img-one-700.jpg'
import imgTwo700 from '../assets/images/menu-preview/img-two-700.jpg'
import imgThree700 from '../assets/images/menu-preview/img-three-700.jpg'
import imgFour700 from '../assets/images/menu-preview/img-four-700.jpg'

//1440
import imgOne1440 from '../assets/images/menu-preview/img-one-1440.jpg'
import imgTwo1440 from '../assets/images/menu-preview/img-two-1440.jpg'
import imgThree1440 from '../assets/images/menu-preview/img-three-1440.jpg'
import imgFour1440 from '../assets/images/menu-preview/img-four-1440.jpg'


const pizzaMenuPreview = [
    {
        id: 1,
        img375: imgOne375,
        img700: imgOne700,
        img1440: imgOne1440,
        name: 'Брускетта с ростбифом и вялеными томатами',
        price: 500.00,
        currency: '₽',
        description:
            'Изысканная закуска, которая сочетает в себе хрустящий хлеб, нежный ростбиф и сладковатые вяленые томаты. Это блюдо идеально подходит для вечеринок или в качестве легкого перекуса.',
    },
    {
        id: 2,
        img375: imgTwo375,
        img700: imgTwo700,
        img1440: imgTwo1440,
        name: 'Шашлычки из курицы в беконе',
        price: 900.00,
        currency: '₽',
        description:
            'Нежное куриное мясо, завёрнутое в хрустящий бекон, станет отличным угощением для любого праздника или семейного ужина.',
    },
    {
        id: 3,
        img375: imgThree375,
        img700: imgThree700,
        img1440: imgThree1440,
        name: 'Том ям',
        price: 500.00,
        currency: '₽',
        description:
            'Острый и ароматный тайский суп. Это блюдо популярно не только в Таиланде, но и во всем мире благодаря своему яркому вкусу и полезным свойствам.',
    },
    {
        id: 4,
        img375: imgFour375,
        img700: imgFour700,
        img1440: imgFour1440,
        name: 'Фунгетти с говядиной и грибами',
        price: 1000.00,
        currency: '₽',
        description:
            'Аппетитное итальянское блюдо, сочетающее в себе нежную говядину, ароматные грибы и пасту. Это блюдо идеально подходит для уютного семейного ужина или встречи с друзьями.',
    },
    // {
    //     id: 5,
    //     img375: imgFive375,
    //     img700: imgFive700,
    //     img1440: imgFive1440,
    //     name: 'Пицца Маргарита',
    //     price: 800.00,
    //     currency: '₽',
    //     description:
    //         'Обманчиво простая пицца «Маргарита» готовится из базилика, свежей моцареллы и помидоров. Есть причина, по которой это основной продукт итальянской кухни и один из самых популярных видов пиццы в стране.',
    // },
    // {
    //     id: 6,
    //     img375: imgSix375,
    //     img700: imgSix700,
    //     img1440: imgSix1440,
    //     name: 'Пицца с курицей-барбекю',
    //     price: 1000.00,
    //     currency: '₽',
    //     description:
    //         'Если вы любите курицу-барбекю и любите пиццу, почему бы не соединить их вместе? Это уже давно стало культовым фаворитом среди спортивных болельщиков и студентов колледжей.',
    // },
]

export default pizzaMenuPreview;