import { Link } from "react-router-dom";
import React from 'react'
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Lottie from "lottie-react";
import "react-alice-carousel/lib/alice-carousel.css";
/*import HeroVideo from '../../assets/images/hero/hero-bg.mp4';*/
import welcomeAnimation from "../../assets/images/hero/Animation-welcome.json";
import menuAnimation from "../../assets/images/hero/Animation-menu2.json";

import img700_l from '../../assets/images/hero/Hero_L_t.webp'
import img700_r from '../../assets/images/hero/Hero_R_t.webp'
import img375_l from '../../assets/images/hero/Hero_L_t-375.webp'
import img375_r from '../../assets/images/hero/Hero_R_t-375.webp'

const Hero = () => {

 
  const styleanim = {
    // width:200
  };
  return (
    <section className="hero-section">
      {/* <video autoPlay loop muted playsInline className="hero-video" >
        <source src={HeroVideo} type="video/mp4" />
      </video> */}

      {/* <LazyLoadImage
        className="hero-video"
        src={img375}
        srcSet={`${img375} 1440w, ${img375} 700w, ${img375} 375w`}
        sizes="(min-width: 1440px) 1440px, (min-width: 700px) 700px, 375px"
        alt="4:20 restaurant interior with people at the tabel and the staff serving the customers"
      /> */}
       <section className="hero-section_i">
          <LazyLoadImage
            className="hero-img_l"
            src={img375_l}
            srcSet={`${img700_l} 1440w, ${img700_l} 700w, ${img375_l} 375w`}
            sizes="(min-width: 1440px) 1440px, (min-width: 700px) 700px, 375px"
            alt="4:20 restaurant interior with people at the tabel and the staff serving the customers"
          />
          <LazyLoadImage
            className="hero-img_r"
            src={img375_r}
            srcSet={`${img700_r} 1440w, ${img700_r} 700w, ${img375_r} 375w`}
            sizes="(min-width: 1440px) 1440px, (min-width: 700px) 700px, 375px"
            alt="4:20 restaurant interior with people at the tabel and the staff serving the customers"
          />
      </section>

      <section className="header-info flex-container flex-column txt-center pop-font txt-white">
        <motion.div
          initial={{ opacity: 0, translateX: 300 }}
          whileInView={{ opacity: 1, translateX: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 2 }}
        >
          <span> 
          <iframe src={'https://4-20mirnyj.ru/api/backend/dish/'} className="iframec"></iframe>
          <Lottie className="amination"
      animationData={welcomeAnimation}
      style={styleanim}
          />
          </span>
          <h1 className="txt-white">ПОПРОБУЙТЕ ЧТО-ТО УДИВИТЕЛЬНОЕ</h1>
         
        </motion.div>

        <div className="header-btns flex-container flex-row">
          {/* <Link className=" passive-button-style" to="/blog">
            Read Blog
          </Link> */}
          <Link className=" passive-button-style  " to="/menu">
          <Lottie className="amination" animationData={menuAnimation}
          style={styleanim}>
          

          </Lottie>
    
        
            МЕНЮ
          </Link>
        </div>
        <p className="txt-white">
          Быстро и легко
          </p>
          <p className="txt-white">
          заказать любимую еду
          </p>
      </section>

    </section>

  );
}

export default Hero;