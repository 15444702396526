import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AddToCartButton from '../cart/AddToCartButton';
import Attribute from './Attribute';
import ResetLocation from "../../helpers/ResetLocation";

const MenuGridItem = ({ singleProduct, handleAddProduct, handleRemoveProduct }) => {

  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [targetAttribute, setTargetAttribute] = useState('');
  // console.log(singleProduct);
  const handleSelectedAttributes = (attributeId, attributeValue) => {
    setTargetAttribute(attributeValue);
    const newSelectedAttribute = { attributeId, attributeValue };
    setSelectedAttributes(prevAttributes => {
      const existingAttributeIndex = prevAttributes.findIndex(
        attribute => attribute.attributeId === newSelectedAttribute.attributeId
      );
      if (existingAttributeIndex !== -1) {
        const updatedAttributes = [...prevAttributes];
        updatedAttributes[existingAttributeIndex] = { ...newSelectedAttribute };
        return updatedAttributes;
      } else {
        return [...prevAttributes, newSelectedAttribute];
      }
    });
  };
  const handleClick = ({ target}) => {
      if (!document.fullscreenElement) {
        target.requestFullscreen()
        .catch((error) => console.log(error));
         } else {
          document.exitFullscreen();
         }
  }


  return (
    <article className="menu-grid-item flex-container flex-column txt-white">
      {/* <Link onClick={ResetLocation} to={`/menu/${singleProduct.id}`} >  */}
        
        {/* <img 
        src={singleProduct.image}
        srcSet={` ${singleProduct.image} 700w, ${singleProduct.image_S} 375w`}
        sizes="(min-width: 700px) 700px, 375px"  
        alt={`${singleProduct.name}`} 
        className="menu-item-link" 
        onClick={handleClick}
        /> */}

        <img 
            src={singleProduct.image_S}  // Загружаем меньшее изображение по умолчанию
            srcSet={` ${singleProduct.image} 1440w, ${singleProduct.image_S} 700w`}
            sizes="(min-width: 1440px) 1440px, 700px"  
            alt={`${singleProduct.name}`} 
            className="menu-item-link" 
            onClick={handleClick}
        />        

        {/* <img src={singleProduct.image.replace(/^.*?:\/\/.*?(?=\/|$)/,'')} alt={`${singleProduct.name}`} className="menu-item-link" onClick={handleClick}/> */}

      {/* </Link> */}
      <h3>{singleProduct.name}</h3>
      <p>{singleProduct.description}</p>
      {/* {singleProduct.status.length === 0 ? null :
        singleProduct.status?.map(attribute => (
          <Attribute
            key={attribute.id}
            className="attributes"
            handleSelectedAttributes={handleSelectedAttributes}
            attribute={attribute}
            targetAttribute={targetAttribute}
          />
        ))
      } */}
      <div className="price">
        {singleProduct.sale === true ?
          <section className="sale-pricing">
            <p className="price-num-before"><span>₽</span>{singleProduct.priceBefore}</p>
            <p className="price-num"><span>₽</span>{singleProduct.price}</p>
          </section>
          :
          <p className="price-num"><span>₽</span>{singleProduct.price}</p>
        }
        <AddToCartButton
          handleAddProduct={handleAddProduct}
          handleRemoveProduct={handleRemoveProduct}
          singleProduct={singleProduct}
          selectedAttributes={selectedAttributes}
          targetAttribute={targetAttribute}
          setTargetAttribute={setTargetAttribute}
        />
      </div>
    </article>
  );
};

export default MenuGridItem;
