import React from "react";

export default class MenuSliderCategories extends React.Component {
  render() {
    const { category_name, changeCategory } = this.props;
    return (
      <li>
        <button onClick={() => changeCategory(category_name.name)}>
          {category_name.name}
        </button>
      </li>
    );
  }
}
