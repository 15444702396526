import React from "react";
import MenuCategories from "./MenuCategories";
import ScrollButton from "../../helpers/ScrollBtn";
import MenuGridItem from "./MenuGridItem";
import ReactPaginate from 'react-paginate';
import { useState, useEffect } from "react";
import ResetLocation from "../../helpers/ResetLocation";
import { motion } from "framer-motion";

const Menu =  ({ allProducts,
  activeCategory,
  allCategories,
  changeCategory,
  handleAddProduct,
  handleRemoveProduct,
  findMenuItem
}) => {

  const [itemOffset, setItemOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(itemOffset + 999);
  const [currentProducts, setcurrentProducts] = useState(allProducts.slice(itemOffset, endOffset));
  const [pageCountProducts, setpageCountProducts] = useState(Math.ceil(allProducts.length / 999));

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 999) % allProducts.length;
    setItemOffset(newOffset);
    ResetLocation();
  };

  const resetPagination = () => {
    setItemOffset(0);
    setEndOffset(6);
  };

  useEffect(() => {
    document.title = `${activeCategory} | 4:20`;
  
    // Сортируем продукты на основе соответствия их категорий с AllCategories
    const sortedProducts = allProducts.sort((a, b) => {
      // Находим индекс категории для каждого продукта в AllCategories
      const categoryAIndex = allCategories.findIndex(category_name => category_name.name === a.category_name); // `a.category` - поле продукта, содержащее id категории
      const categoryBIndex = allCategories.findIndex(category_name => category_name.name === b.category_name); // `b.category` - аналогично
  
      // Если категорию не нашли, возвращаем значение по умолчанию -1 для несортируемых категорий
      return (categoryBIndex === -1 ? allCategories.length : categoryAIndex) - 
             (categoryAIndex === -1 ? allCategories.length : categoryBIndex);
    });
  
    // Устанавливаем продукты для текущей страницы
    console.log("allProducts", allProducts);
    console.log("sortedProducts", sortedProducts);
    setEndOffset(itemOffset + 999);
    setcurrentProducts(sortedProducts.slice(itemOffset, endOffset));
    setpageCountProducts(Math.ceil(sortedProducts.length / 999));
  }, [allProducts, setEndOffset, endOffset, itemOffset, activeCategory, allCategories]);

  return (
       <motion.main
      className="menu-main"
      initial={{ opacity: 0, translateX: -300 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: -300 }}
      transition={{ duration: 1 }}
    >
      <MenuCategories
        activeCategory={activeCategory}
        allCategories={allCategories}
        changeCategory={changeCategory}
        resetPagination={resetPagination}
        findMenuItem={findMenuItem}
      />
      {currentProducts.length === 0 ?
        <article className="menu-grid">
          <p className="nothing-found">Ничего не найдено... повторите ввод или перезагрузите страницу</p>
        </article> :
        <article className="menu-grid">
          {currentProducts.map((singleProduct) => (
            <MenuGridItem
              key={singleProduct.id}
              singleProduct={singleProduct}
              handleAddProduct={handleAddProduct}
              handleRemoveProduct={handleRemoveProduct}
            />
          ))
          }
          <ScrollButton />
        </article>}

      <ReactPaginate
        className="pagination"
        breakLabel="..."
        nextLabel=" &#62;"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCountProducts}
        previousLabel="&#60;"
        renderOnZeroPageCount={null}
      />
    </motion.main>
  );
}

export default Menu;
