//375
import pizzaOne375 from '../assets/images/menu-slider/pizza-one-375.webp'
import pizzaTwo375 from '../assets/images/menu-slider/pizza-two-375.webp'
import pizzaThree375 from '../assets/images/menu-slider/pizza-three-375.webp'
import SalmonPoke375 from '../assets/images/menu-slider/sushi-one-375.webp'
import VeggiePoke375 from '../assets/images/menu-slider/sushi-two-375.webp'
import MiniSalmon375 from '../assets/images/menu-slider/sushi-three-375.webp'
import ChickenAlfredo375 from '../assets/images/menu-slider/pasta-one-375.webp'
import AllaGricia375 from '../assets/images/menu-slider/pasta-two-375.webp'
import SheetPan375 from '../assets/images/menu-slider/pasta-three-375.webp'
//700
import pizzaOne700 from '../assets/images/menu-slider/pizza-one-700.webp'
import pizzaTwo700 from '../assets/images/menu-slider/pizza-two-700.webp'
import pizzaThree700 from '../assets/images/menu-slider/pizza-three-700.webp'
import SalmonPoke700 from '../assets/images/menu-slider/sushi-one-700.webp'
import VeggiePoke700 from '../assets/images/menu-slider/sushi-two-700.webp'
import MiniSalmon700 from '../assets/images/menu-slider/sushi-three-700.webp'
import ChickenAlfredo700 from '../assets/images/menu-slider/pasta-one-700.webp'
import AllaGricia700 from '../assets/images/menu-slider/pasta-two-700.webp'
import SheetPan700 from '../assets/images/menu-slider/pasta-three-700.webp'

const menuSliderCategories = [
    {
        name: 'Закуски',
        id: 'sushi',
        
    },
    {
        name: 'Пицца',
        id: 'pizza',
    },
    {
        name: 'Паста',
        id: 'pasta',
       
    },
]
const menuSliderProducts = [
    {
        id: 'pepperoni-pizza',
        name: 'Кватро формаджи',
        describtion:
            'Моцарелла, гауда, пармезан, дор блю',
        price: (500).toFixed(2),
        img375: pizzaOne375,
        img700: pizzaOne700,
        category_name: 'pizza',
    },
    {
        id: 'bbq-chicken-pizza',
        name: 'Аль сальмоне',
        describtion:
            'Моцарелла, лосось, черри, лук зелён., творожный сыр',
        price: (1000).toFixed(2),
        img375: pizzaTwo375,
        img700: pizzaTwo700,
        category_name: 'pizza',
    },
    {
        id: 'cheese-pizza',
        name: 'С грушей и горгонзолой',
        describtion: 'Моцарелла, груша, горгонзола, орех грецкий',
        price: (500).toFixed(2),
        img375: pizzaThree375,
        img700: pizzaThree700,
        category_name: 'pizza',
    },
    {
        id: 'salmon-poke',
        img375: SalmonPoke375,
        img700: SalmonPoke700,
        name: 'Шашлычки из курицы в беконе',
        describtion:
            'Курица, черри, бекон, тартар',
        price: (1000).toFixed(2),
        category_name: 'sushi',
    },

    {
        id: 'veggie-poke',
        img375: VeggiePoke375,
        img700: VeggiePoke700,
        name: 'Морепродукты в манго соусе',
        describtion: 'Креветка, лосось, тунец, черри, пюре манго, руккола, терияки',
        price: (800).toFixed(2),
        category_name: 'sushi',
    },
    {
        id: 'mini-salmon-set-22-pcs',
        img375: MiniSalmon375,
        img700: MiniSalmon700,
        name: 'Мидии в створках спайси',
        describtion:
            'Мидии, сливки, чеснок, кинза',
        price: (3500).toFixed(2),
        category_name: 'sushi',
    },
    {
        id: 'chicken-alfredo',
        img375: ChickenAlfredo375,
        img700: ChickenAlfredo700,
        name: 'Удон с морепродуктами',
        describtion:
            'Удон, креветки, молоко кокосовое, сливки, лайм, кунжут',
        price: (200).toFixed(2),
        category_name: 'pasta',
    },
    {
        id: 'pasta-alla-gricia',
        img375: AllaGricia375,
        img700: AllaGricia700,
        name: 'С говядиной и грибами',
        describtion:
            'Фунгетти, говядина, лук, шампиньоны, сливки, сметана, гауда, масло трюфельное',
        price: (900).toFixed(2),
        category_name: 'pasta',
    },
    {
        id: 'sheet-pan-burrata-caprese-gnocchi',
        img375: SheetPan375,
        img700: SheetPan700,
        name: 'С цыпленком и томатами',
        describtion:
            'Триколлини, курица, томаты вяленые',
        price: (500).toFixed(2),
        category_name: 'pasta',
    },
]

export { menuSliderProducts, menuSliderCategories };